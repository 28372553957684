@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url("fonts/poppins-v15-latin-ext_latin_devanagari-regular.woff2") format('woff2');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url("fonts/poppins-v15-latin-ext_latin_devanagari-regular.ttf") format('truetype');
}
@font-face {
  font-family: 'Press Start 2P';
  src: local('Press Start 2P'), url("fonts/PressStart2P-Regular.ttf") format('truetype');
}
//FONTS
$primary-font: 'Poppins', sans-seriff;
$secondary-font: 'Press Start 2P', cursive;

//COLORS
$primary-color: #212121;
$secondary-color: WHITE;
$svg-color: #6C63FF;
$border-color: #d9d9d9;

//SIZES
$mobile-size: 768px;
$tablet-size: 1025px;
$desktop-size: 1400px;
$landscape-phone-height: 500px;
