@import '../../../constant/global';

.elemWrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .elemTitle{
    display: flex;
    align-items: center;
    font-weight: bold;

    .icon{
      font-size: 1rem;
      color: $svg-color;
    }

    .iconTitle{
      font-size: 1rem;
    }

  }
}

