.carouselContainer {
    display: flex;
    height: 100%;
    flex-direction: row;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.carousel {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    .slide {
        position: relative;
        height: 100%;
        min-width: 100%;
    }
}

%button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: 40px;
    &:hover {
        cursor: pointer;
        background-color: transparent;
    }
}

#left {
    @extend %button;
    &:hover {
        background-color: rgba(20,20,20,.1);;
    }
}

#right {
    @extend %button;
    &:hover {
        background-color: rgba(20,20,20,.1);;
    }
}

.carousel::-webkit-scrollbar {
    width: 7px;
}

.carousel::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.carousel::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.carousel::-webkit-scrollbar-thumb:hover {
    background: #555;
}
