@import '../../constant/global';

.container {
  position: relative;
  z-index: 3;
  @media screen and (max-width: $tablet-size) {
    width: 100%;
  }

  .title {
    height: 10%;
    font-size: 23px;
    @media screen and (min-width: $tablet-size) {
      font-size: 30px;
    }
    @media screen and (max-height: $landscape-phone-height){
      height: 20%;
      margin-bottom: 5px;
    }
  }

  .content {
    height: 90%;
  }

  .backgroundTitle {
    display: none;
    position: absolute;
    bottom: -200px;
    right: 0;
    color: transparent;
    opacity: 0.4;
    text-transform: uppercase;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: grey;
    @media screen and (min-width: $tablet-size) {
      display: block;
      font-size: 65px;
    }
    @media screen and (min-width: $desktop-size) {
      display: block;
      font-size: 90px;
    }

  }
}
