@import "../../constant/global";

.heroHeader {
  position: relative;
  height: 18vh;
  z-index: 6;
  font-family: $primary-font;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  padding: 30px 0 0;


  .logo {
    width: 100px;
    height: auto;
  }
  .logo:hover{
    cursor: pointer;
  }

  @media screen and (min-width: $mobile-size) {
    padding: 50px 0 0;
    .logo {
      width: 150px;
    }

  }
  @media screen and (max-height: $landscape-phone-height){
    padding: 20px 0 0;
    .logo{
      width: 60px;
      height: auto;
    }
  }



}
