@import '../../constant/global';

.wrapper {
  position: fixed;
  z-index: 5;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: $secondary-color;

  .navigation {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;

    .list {
      list-style: none;
      text-align: center;
      margin-top: 18vh;
      display: flex;
      flex-direction: column;
      font-size: 25px;
      @media screen and (max-height: $landscape-phone-height){
        font-size: 10px;
        margin-top: 24vh;
      }

      li {
        padding-top: 25px;
        margin-bottom: 10px;
        border-bottom: 2px solid $svg-color;
        width: 55vw;
        @media screen and (max-height: $landscape-phone-height){
          width: 30vw;
          padding-top: 10px;
        }
      }
    }

    .navigationFooter {
      position: absolute;
      bottom: 5%;
      border-bottom: 1px solid $svg-color;
      @media screen and (max-height: $landscape-phone-height){
        font-size:  10px;
      }


      a {
        color: black;
        display: flex;
        padding: 10px 0;
        background-color: transparent;
        border: none;
        align-items: center;
        justify-content: space-around;
      }

      span {
        padding-left: 7px;
      }
    }
  }

}