@import '../../constant/global';

.externalContainer {
  align-self: center;
  background: transparent;
  max-height: 44vh;
  overflow-x: hidden;
  overflow-y: auto;

  .container {
    margin: 20px;

    .box {
      border: 1px solid $border-color;
      padding: 40px;
      display: grid;
      font-size: 12px;
      place-items: center;
      text-align: center;
      color: $primary-color;
      transition: 0.5s;

      &:hover {
        background-color: rgba($svg-color,0.2);
      }

      .img {
        position: relative;
        max-width: 100px;
        margin-bottom: 10px;
      }
    }
  }
}