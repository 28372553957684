@import "../../constant/global";


@mixin hamburger-line {
  width: 100%;
  height: 3px;
  background-color: $svg-color;
  position: absolute;
}

.hamburger {
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  &:focus {
    outline: 0;
  }
  &__box {
    width: 28px;
    height: 22px;
    display: block;
    position: relative;
    .hamburger__inner {
      @include hamburger-line;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: background-color 0.3s 0.1s ease-in-out;
    }
    .hamburger__inner::before,
    .hamburger__inner::after {
      content: "";
      @include hamburger-line;
      left: 0;
      transition: transform 0.3s 0.2s ease-in-out;
    }
    .hamburger__inner::before {
      top: -8px;
    }
    .hamburger__inner::after {
      top: 8px;
    }
  }
  .hamburgerActive &__inner {
    background-color: transparent;
  }
  .hamburgerActive &__inner::before {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburgerActive &__inner::after {
    transform: translateY(-8px) rotate(-45deg);
  }
}
