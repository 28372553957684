@import '../../constant/global';


.desktopNavigation {
  display: none;
  @media screen and (min-width: $tablet-size){
    margin-right: 130px;
  }

  ul {
    list-style: none;
    display: flex;
    margin-bottom: 0;
  }

  li {

    font-size: 15px;
    margin: 0 15px;
    display: inline-block;

  }
  li:after{
    display: block;
    content: '';
    border-bottom: 3px solid $svg-color;
    transform: scaleX(1);
    transition: transform 0.25s ease-in-out;
  }
  li:hover{
    cursor: pointer;
  }
  li:hover:after { transform: scaleX(0.2); }

}
a {
  color: black;
  display: flex;
  padding: 10px 0;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: space-around;
}
a:hover{
  color: $svg-color;

}
span{
  margin-left: 5px;
}