@import '../../constant/global';

.container {
    padding: 1rem;
    position: relative;
    display: grid;
    grid-gap: 1rem;
    align-items: center;
    justify-items: center;
}

@media screen and (min-width: 560px) and (max-width: 914px) {
    .container{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 915px) and (max-width: 1024px) {
    .container{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width: $tablet-size) and (max-width: 1199px) {
    .container{
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (min-width: 1270px) and (max-width: 1309px) {
    .container {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 0.5rem;
    }
}

@media screen and (min-width: 1310px) {
    .container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 1830px) {
    .container {
        grid-template-columns: repeat(3, 1fr);
    }
}


