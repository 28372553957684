@import '../constant/global';

.layoutWrapper{
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 80%;
}
.contentWithSvgWrapper{
  display: flex;
  justify-content: space-between;
  height: 48%;
  margin-top: 4vh;
  width: 100%;
  @media screen and (min-width: $tablet-size) {
    > *{
      width: 48%;
    }
  }
  @media screen and (max-height: $landscape-phone-height){
    margin-top: 0;
  }
}
.svgWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $tablet-size){
    display: none;
  }
  .heroSvg{
    max-width:120%;
    max-height:120%;
    transform: translateY(10%);
    @media screen and (max-width: $tablet-size) {
      position: fixed;
      display: block;
      height: auto;
      bottom: 2vh;
      width: 20vh;
      left: 50%;
      transform: translateX(-50%);
    }

  }
}









