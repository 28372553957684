@import '../../constant/global';
.footerContainer{
  position: fixed;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
  height: 15%;
  background-color: $primary-color;
  z-index: -1;
  @media screen and (min-width: $tablet-size) {
    left: unset;
    right: 0;
    width: 35vw;
    height: 100%;
  }
  span{
    display: inline;
    color: $secondary-color;
    font-family: $secondary-font;
    font-size: 0.5rem;
    margin: 0 1rem 0.5rem 2rem;
    @media screen and (max-width: $tablet-size) {
      font-size: 0.35rem;
      margin: 0 0.5rem 0.2rem 0;
    }
    a{
      display: inline;
    }

  }

}